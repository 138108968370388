import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle } from '@wix/thunderbolt-symbols'
import { ScreenInInitCallbackFactory } from '../screenInInitCallbackFactory'
import { ScreenIn as EditorScreenIn } from './editorScreenInFactory'
import { ScreenInInitCallbackSymbol } from '../symbols'
import { ModelUpdatesHandlersImplementor } from 'ds-feature-model-updates-invoker'

export const editor: ContainerModuleLoader = (bind) => {
	bind(ScreenInInitCallbackSymbol).to(ScreenInInitCallbackFactory)
	bind(ModelUpdatesHandlersImplementor, LifeCycle.AppWillLoadPageHandler).to(EditorScreenIn)
}
