import { name, name as featureName, ScreenInInitCallbackSymbol } from '../symbols'
import { RenderDoneSymbol } from 'ds-feature-viewer-manager-layout-api'
import type { IRenderDone } from 'ds-feature-viewer-manager-layout-api'
import { named, withDependencies } from '@wix/thunderbolt-ioc'
import type { IViewMode } from 'ds-feature-view-mode'
import type { IModelUpdatesHandlersImplementor } from 'ds-feature-model-updates-invoker'
import type { IAppWillLoadPageHandler } from '@wix/thunderbolt-symbols'
import {
	CompsLifeCycleSym,
	DSViewModeAPISymbol,
	FeatureStateSymbol,
	ICompsLifeCycle,
	PageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import type { Actions, GetScreenInInitCallback, ScreenInFeatureState, ScreenInHandlers } from '../types'
import { IFeatureState } from 'thunderbolt-feature-state'

const SCREEN_IN_DS_CALLBACK = 'ScreenInDSCallback'

const editorScreenInFactory = (
	featureConfig: { compIdToActions: Actions },
	featureState: IFeatureState<ScreenInFeatureState>,
	getScreenInInitCallback: GetScreenInInitCallback,
	compsLifeCycle: ICompsLifeCycle,
	renderDoneService: IRenderDone,
	viewModeApi: IViewMode
): IModelUpdatesHandlersImplementor<ScreenInHandlers> & IAppWillLoadPageHandler => {
	const compLifecycleRegisteredCompIds: Set<string> = new Set()
	let shouldInitScreenIn = false
	let lastViewMode = 'desktop'
	let compIdToDisplayedAndElementMap: Record<string, [string, HTMLElement]> = {}

	function destroy(shouldClearState: boolean) {
		// reset cache
		compIdToDisplayedAndElementMap = {}

		if (compLifecycleRegisteredCompIds.size) {
			compsLifeCycle.unregisterToCompLifeCycle([...compLifecycleRegisteredCompIds], SCREEN_IN_DS_CALLBACK)
			compLifecycleRegisteredCompIds.clear()
		}

		const state = featureState.get()

		if (state) {
			state.viewport.stop()

			if (shouldClearState) {
				state.screenInManager.clearState()
			}
		}
	}

	function toggleScreenIn({ shouldEnableScreenIn, viewMode }: { shouldEnableScreenIn: boolean; viewMode: string }) {
		shouldInitScreenIn = shouldEnableScreenIn
		lastViewMode = viewMode

		if (shouldInitScreenIn) {
			const compIds = Object.keys(featureConfig.compIdToActions || {})
			const isDuringViewModeSwitch = viewModeApi.isDuringViewModeSwitch()

			// cache for protecting from unregister error
			compIds.forEach((compId) => compLifecycleRegisteredCompIds.add(compId))

			// tap into lifecycle (mount) hook to get and store the displayedId - support for Repeaters
			compsLifeCycle.registerToCompLifeCycle(
				compIds,
				SCREEN_IN_DS_CALLBACK,
				(compId: string, displayedId: string, element: HTMLElement) => {
					compIdToDisplayedAndElementMap[compId] = [displayedId, element]
				}
			)

			renderDoneService.registerToRenderDone(
				() => {
					if (isDuringViewModeSwitch) {
						destroy(true)
					}

					const initCallback = getScreenInInitCallback()

					if (!initCallback) {
						return
					}

					compIds.forEach((compId) => {
						const [displayedId, element] = compIdToDisplayedAndElementMap[compId]
						initCallback(compId, displayedId, element)
					})
				},
				{ once: true }
			)
		} else {
			destroy(!shouldInitScreenIn)
		}
	}

	return {
		featureName,
		appWillLoadPage() {
			destroy(!shouldInitScreenIn)
			toggleScreenIn({ shouldEnableScreenIn: shouldInitScreenIn, viewMode: lastViewMode })
		},
		handlers: {
			toggleScreenIn,
		},
	}
}

export const ScreenIn = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(FeatureStateSymbol, name),
		ScreenInInitCallbackSymbol,
		CompsLifeCycleSym,
		RenderDoneSymbol,
		DSViewModeAPISymbol,
	],
	editorScreenInFactory
)
